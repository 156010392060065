import './App.css';

import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Redirect,
	Route
} from 'react-router-dom';

import routes from "../route"

// components
import RouteWithSubRoutes from "shared/components/RouteWithSubRoutes";
import NoMatch from 'App/screens/404/NoMatch';


function App() {
	return (
		<div className="App">
			<Router>
				<Switch>
					<Redirect exact from='/' to='/home' />
					{routes.map((route, i) => {
						console.log(route)
						return (
							<RouteWithSubRoutes key={i} {...route} />
						)
					})}
					<Route component={NoMatch} />
				</Switch>
			</Router>
		</div>
	);
}


export default App;