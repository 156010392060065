import { useState } from "react"

export const useCustomState = (initialState = {}) => {
    const [customState, setCustomState] = useState(initialState);

    const handleCustomStateChanges = (newState, key = "") => {
        if (key) setCustomState(oldUser => {
            const newUser = {
                ...oldUser,
                [key]: { ...oldUser[key], ...newState },
            }
            return newUser
        })
        else setCustomState({ ...customState, ...newState });
    };

    const handleDeleteCustomState = (key) => {
        const copyOfCustomState = { ...customState }
        delete copyOfCustomState[key]
        setCustomState(copyOfCustomState)
    };

    const checkCustomStateVal = (keyOne, keyTwo = "", valTrue = "", valFalse) => {

        const keysOne = Object.keys(customState)

        if (keyOne && keyTwo) {
            if (keysOne.includes(keyOne)) {
                const keysTwo = Object.keys(customState[keyOne])
                if (keysTwo.includes(keyTwo)) {
                    if (valTrue) {
                        return customState &&
                            customState[keyOne] &&
                            customState[keyOne][keyTwo] ? valTrue : valFalse
                    }
                    else {
                        return customState &&
                            customState[keyOne] &&
                            customState[keyOne][keyTwo] ? customState[keyOne][keyTwo] : valFalse
                    }
                }
                else return false
            }
            else return false
        }
        else {
            return customState && customState[keyOne] ? customState[keyOne] : valFalse;
        }
    };

    return [customState, handleCustomStateChanges, handleDeleteCustomState, checkCustomStateVal];
};


