export const routeConstants = Object.freeze({
    HOME: {
        name: 'Home',
        route: '/home'
    },
    POLICY: {
        name: 'Policy',
        route: '/policy'
    }
})
