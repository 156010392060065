import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';


const BootstrapInput = styled(InputBase)(({ theme, width, borderColor = "#ced4da" }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "transparent",
        border: '1px solid',
        borderColor: borderColor,
        fontSize: 16,
        color: "white",
        width: width,
        // height: "15px",
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: "#82CBAB",
        },
        "&:disabled": {
            backgroundColor: "transparent",
            fontWeight: "bold"
        }
    }
}));

export default BootstrapInput