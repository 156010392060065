import React from 'react';

import { makeStyles } from '@mui/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import emailjs from 'emailjs-com';

import { Link } from 'react-router-dom';

import Helmet from "react-helmet";

// constants
import map from "shared/constants/Images/map.png";
import logo from "shared/constants/Images/logo.png";

// components
import BootstrapInput from "shared/components/Input/BootstrapInupt";

// contexts
import { useViewport } from "shared/providers/ViewportContext";

// hooks
import { useCustomState } from "shared/hooks/useCustomState";

const Alert = React.forwardRef(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Home = () => {
    const classes = useStyles();
    const { width } = useViewport();

    const isMobile = width < 620;

    // const [loading, setLoading] = useState(false)

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const [info, setInfo] = useCustomState({
        firstname: "",
        lastname: "",
        email: "",
        tel: "",
        desc: "",
        errorFirst: false,
        errorLast: false,
        errorTelEmail: false
    });

    React.useEffect(() => {
        emailjs.init(process.env.REACT_APP_CLIENT_ID);
    }, [])

    const handleSendMail = async () => {
        try {

            setInfo({
                errorFirst: false,
                errorLast: false,
                errorTelEmail: false
            });

            if (
                info.firstname.trim() &&
                info.lastname.trim() &&
                (info.tel.trim() || info.email.trim())
            ) {

                var templateParams = {
                    subject: "Nouveau patient",
                    message: info.desc,
                    firstName: info.firstname,
                    lastName: info.lastname,
                    tel: info.tel,
                    email: info.email
                }

                emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, templateParams)
                    .then(function (response) {
                        handleClick();
                        setInfo({
                            firstname: "",
                            lastname: "",
                            email: "",
                            tel: "",
                            desc: "",
                            errorFirst: false,
                            errorLast: false,
                            errorTelEmail: false
                        })
                    }, function (error) {
                        console.log('FAILED...', error);
                    });
            }

            else {
                setInfo({
                    errorFirst: info.firstname.trim() === "" ? true : false,
                    errorLast: info.lastname.trim() === "" ? true : false,
                    errorTelEmail:
                        info.tel.trim() === "" && info.email.trim() === "" ? true : false
                })
            }

            // setLoading(false)

        } catch (e) {
            // setLoading(false)
        }
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Kine-dom</title>
                <meta name="description" content="Kiné Vannes -centre et Séné -bourg à domicile" data-react-helmet="true" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div className={classes.titleContainer}>
                <div className={classes.titleText}>
                    Kinésithérapie à domicile à Vannes et Séné
                </div>
            </div>
            {
                !isMobile ?
                    <div className={classes.infoContainer}>
                        <div style={{ display: "flex", flexDirection: "row" }}>

                            <div style={{ marginRight: 10 }}>
                                <InnerImageZoom
                                    src={map}
                                    draggable={false}
                                    width={250}
                                    zoomScale={0.5}
                                />
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                marginLeft: 10
                            }}>
                                <div style={{ fontSize: 22, marginBottom: 10, paddingLeft: 15 }}>
                                    Je me déplace à votre domicile dans les quartiers suivants :
                                </div>
                                <div style={{ fontSize: 24, marginBottom: 2, color: "#253E33", fontWeight: "bold" }}>
                                    Vannes : Intra-muros, Rive gauche du port, Tohannic, St Patern
                                </div>
                                <div style={{ fontSize: 24, color: "#253E33", fontWeight: "bold" }}>
                                    Séné : Centre Bourg, Arcal et Kerhuillieu
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ padding: 20 }}>
                            <img src={map} draggable={false} width={"100%"} alt="" />
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 10
                        }}>
                            <div style={{ fontSize: 22, marginBottom: 10, paddingLeft: 15 }}>
                                Je me déplace à votre domicile dans les quartiers suivants :
                            </div>
                            <div style={{ display: "flex", fontSize: 24, color: "#253E33", fontWeight: "bold", marginBottom: 10 }}>
                                <div style={{ minWidth: 100 }}>
                                    Vannes :
                                </div>
                                <div>
                                    Intra-muros, Rive gauche du port, Tohannic, St Patern
                                </div>
                            </div>

                            <div style={{ display: "flex", fontSize: 24, marginBottom: 2, color: "#253E33", fontWeight: "bold" }}>
                                <div style={{ minWidth: 100 }}>
                                    Séné :
                                </div>
                                <div>
                                    Centre Bourg, Arcal et Kerhuillieu
                                </div>
                            </div>
                        </div>

                    </div>
            }

            <div style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                marginTop: 30
            }}>
                <div className={classes.infoText}>
                    Kinésithérapeute conventionnée, diplomée depuis 2013 (Bruxelles, Dunkerque, Paris, Sarzeau)
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    margin: 30
                }}
            >
                <div style={{
                    display: "flex",
                    flexDirection: "column"
                }}>
                    <div className={classes.infoText}>
                        Prenez contact ici  (et je vous recontacterai dans les prochaines heures)
                    </div>
                    <div className={classes.inputsContainer}>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                            <div style={{ minWidth: 80 }}>
                                Nom
                            </div>
                            <BootstrapInput
                                spellCheck={false}
                                fullWidth
                                value={info.lastname}
                                onChange={(event) => setInfo({ lastname: event.target.value })}
                                borderColor={info.errorLast ? "tomato" : "white"}
                            />
                        </div>

                        <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                            <div style={{ minWidth: 80 }}>
                                Prénom
                            </div>
                            <BootstrapInput
                                spellCheck={false}
                                fullWidth
                                value={info.firstname}
                                onChange={(event) => setInfo({ firstname: event.target.value })}
                                borderColor={info.errorFirst ? "tomato" : "white"}
                            />
                        </div>


                        {
                            !isMobile &&
                            <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                                <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                                    <div style={{ minWidth: 80 }}>
                                        Téléphone
                                    </div>
                                    <BootstrapInput
                                        spellCheck={false}
                                        fullWidth
                                        value={info.tel}
                                        onChange={(event) => setInfo({ tel: event.target.value })}
                                        borderColor={info.errorTelEmail ? "tomato" : "white"}
                                    />
                                </div>

                                <div style={{ paddingRight: 10, fontWeight: "bold" }}>
                                    ou
                                </div>


                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{ minWidth: 60 }}>
                                        email
                                    </div>
                                    <BootstrapInput
                                        spellCheck={false}
                                        fullWidth
                                        value={info.email}
                                        onChange={(event) => setInfo({ email: event.target.value })}
                                        borderColor={info.errorTelEmail ? "tomato" : "white"}
                                    />
                                </div>
                            </div>
                        }


                        {
                            isMobile &&
                            <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                                <div style={{ minWidth: 80 }}>
                                    Telephone
                                </div>
                                <BootstrapInput
                                    spellCheck={false}
                                    fullWidth
                                    value={info.tel}
                                    onChange={(event) => setInfo({ tel: event.target.value })}
                                    borderColor={info.errorTelEmail ? "tomato" : "white"}
                                />
                            </div>
                        }


                        {
                            isMobile &&
                            <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
                                <div style={{ minWidth: 80 }}>
                                    email
                                </div>
                                <BootstrapInput
                                    spellCheck={false}
                                    fullWidth
                                    value={info.email}
                                    onChange={(event) => setInfo({ email: event.target.value })}
                                    borderColor={info.errorTelEmail ? "tomato" : "white"}
                                />
                            </div>
                        }


                        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                            <div style={{ margin: 10 }}>
                                Demande (prescription)
                            </div>
                            <BootstrapInput
                                spellCheck={false}
                                fullWidth
                                multiline
                                rows={4}
                                value={info.desc}
                                onChange={(event) => setInfo({ desc: event.target.value })}
                            />
                        </div>

                    </div>

                    <div style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "center",
                        marginTop: 20
                    }}>
                        <div
                            className={classes.submitButton}
                            onClick={handleSendMail}
                        >
                            Valider !
                        </div>

                    </div>

                </div>

                {
                    !isMobile &&
                    <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", padding: 40 }}>
                        <img src={logo} draggable={false} width={"100%"} alt="" />
                    </div>
                }


            </div>


            {
                isMobile &&
                <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center", padding: 10 }}>
                    <img src={logo} draggable={false} width={"100%"} alt="" />
                </div>
            }

            <div className={classes.logoContainer}>

                <Link
                    style={{ color: "white", fontSize: 14, marginRight: 5 }}
                    to={"/policy"}
                    draggable="false"
                >
                    {"Politique de confidentialité"}
                </Link>

                <div style={{ fontSize: 32, fontWeight: "bold", marginLeft: "auto" }}>
                    kine-dom.com
                </div>
            </div>

            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Merci de votre confiance !
                </Alert>
            </Snackbar>

        </div >
    )
}


const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
        backgroundColor: "#5fa989",
        minHeight: "100vh",
        color: "white"
    },
    titleContainer: {
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 30
    },
    titleText: {
        fontSize: 40,
        textAlign: "center",
        color: "#253E33"
    },
    infoContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    infoText: {
        fontSize: 22,
        textAlign: "center"
    },
    inputsContainer: {
        marginTop: 22,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
    },
    logoContainer: {
        marginTop: 20,
        display: "flex",
        flex: 1,
        alignItems: "center",
        padding: 10
    },
    submitButton: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 100,
        height: 40,
        padding: 10,
        backgroundColor: "#82CBAB",
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: .1,
        cursor: "pointer",
        borderColor: "#5fa989",
        '&:hover': {
            borderColor: "#82CBAB"
        }
    }
}));

export default React.memo(Home)