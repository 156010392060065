import React from "react"

import { makeStyles } from '@mui/styles';

import { useHistory } from "react-router-dom";

// components
import Button from '@mui/material/Button';


const NoMatch = () => {

    const classes = useStyles();
    const history = useHistory();

    const handleRedirect = () => {
        history.push("/home")
    }

    return (
        <div className={classes.root}>
            404

            <div style={{ marginTop: 50 }}>
                <Button
                    variant="contained"
                    style={{ width: "200px", backgroundColor: "#CF1E49" }}
                    onClick={handleRedirect}
                >
                    {"Retourner à l'acceuil"}
                </Button>
            </div>
        </div >
    )
}


const useStyles = makeStyles(() => ({
    root: {
        flex: 1,
        paddingTop: 50,
        backgroundColor: "#5fa989",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    }
}));

export default NoMatch